import { navigate } from 'gatsby';
import * as React from 'react'
import { useApplicationContext } from "../../provider";
import { isLoggedIn } from '../services/auth';

export default function PaymentOptions({ imgSrc, userPrompt, prompt }) {

    const { applicationState, setApplicationState } = useApplicationContext();

    const onRemixClick = (e) => {
        e.preventDefault();
        setApplicationState({
            ...applicationState,
            detailedDescription: prompt,
        });
        if (isLoggedIn()) {
            navigate("/remix-prompt");
        } else {
            navigate("/signin");
        }
    }

    const onMerchifyClick = (e) => {
        e.preventDefault();
        const domainOrigin = window.location.origin;
        const merchifyArr = [];

        merchifyArr.push({
          id_val: 0,
          original: `${domainOrigin}${imgSrc}`,
          upscaled: `${domainOrigin}${imgSrc}`,
        });
        localStorage.setItem("mergify", JSON.stringify(merchifyArr));
        localStorage.setItem("job_id", ',,,,,');

        if (isLoggedIn()) {
            navigate("/select-merch");
        } else {
            navigate("/signin");
        }
    }

    return (
        <div className="col-lg-4 col-sm-6">
            <div className="box">
                <div className="figure">
                    <img src={imgSrc} alt="featured-image-1" />{" "}
                    <div className="btns">
                        <a href="#" className="btn btn-blue" onClick={onRemixClick}>REMIX <em className="icn-rex" /></a>{" "}
                        <a href="#" className="btn btn-blue" onClick={onMerchifyClick}>MERCHIFY <em className="icn-crt" /></a>
                    </div>
                </div>
                <h3>{userPrompt}</h3>
            </div>
        </div>
    )
}
