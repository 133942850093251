import * as React from "react";
import { withPrefix } from "gatsby";
import { navigate } from "gatsby";
import { useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
// import { sharp } from "gatsby-plugin-sharp/safe-sharp";
import CropImageModal from "./CropImageModal";
import { useApplicationContext } from "../../provider";
import Swal from 'sweetalert2';
import { isLoggedIn } from "../services/auth";
import { useDispatch, useSelector } from 'react-redux';
import { upscaleImage, resetUpscaleImage, selectCarouselsState } from "../sagas/carousels/carouselsSlice";
import { postImage, selectImageState } from "../sagas/image/imageSlice";

const styles = {
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    color: '#10100a',
    width: '100%',
    height: '359px',
  },
  dropzoneText: {
    color: '#000000',
    fontFamily: 'Albert Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    paddingBottom: 5,
    marginBottom: 0,
  },
  thumbsContainer: {
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'center',
  },
  thumb: {
    width: '100%',
    maxWidth: '366px',
    float: 'left',
    borderRadius: '15px',
    boxShadow: 'rgba(87, 87, 225, 0.35) 0 5px 10px',
    position: 'relative',
    margin: '8px 0 0 0',
  },
  thumbInner: {
    width: '100%',
    float: 'left',
    textSlign: 'center',
    borderRadius: '15px',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    maxWidth: '366px',
    borderRadius: '15px',
    height: '366px',
    objectFit: 'cover',
  },
  iconCloseBox: {
    width: '42px',
    height: '42px',
    float: 'right',
    position: 'absolute',
    top: '-16px',
    right: '-16px',
  },
  iconClose: {
    width: '42px',
    height: '42px',
    float: 'left',
    fontWeight: 'inherit',
    fontSize: '24px',
    color: '#ffffff',
    lineHeight: '42px',
    textDecoration: 'none',
    textAlign: 'center',
    background: '#FF3333',
    borderRadius: '360px',
  },
  customStyles: {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      width: 400,
      borderRadius: "20px",
      fontFamily: `"Albert Sans", sans-serif`,
      padding: 24,
    },
  },
  browseBtn: {
    border: '1px solid #10100A',
    borderRadius: '8px',
    padding: '16px 24px',
    color: '#595A5C',
    background: 'transparent',
    textTransform: 'uppercase',
    fontFamily: 'Roboto Mono',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: 16,
  },
  kyaText: {
    fontFamily: 'Roboto Mono',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '28px',
    color: '#10100A',
  },
  kyaShareLinkText: {
    marginTop: 10,
    fontFamily: 'Albert Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#8F0CED',
  },
  kyaApplyTYText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#595A5C',
  },
  input: {
    height: '56px',
    fontSize: '16px',
    marginBottom: '8px',
    background: 'transparent',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

export default function UploadImg() {
  const dispatch = useDispatch();
  const { isUpscalingImageSuccess, isUpscalingImageFailed, UpscaleImageResponseDto } = useSelector(selectCarouselsState);
  const { checkoutState, setCheckoutState } = useApplicationContext();
  const { isPostingImageSuccess, PostImageResponse } = useSelector(selectImageState);

  const currentUser = typeof window !== "undefined" && JSON.parse(localStorage.getItem("currentUser") || "{}");

  // Upload Image: start
  const [previewImage, setPreviewImage] = React.useState("");
  const [showAnimationflag, setShowAnimationflag] = React.useState(false);

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [cropper, setCropper] = useState(<any />);
  const [picture, setPicture] = useState("");

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      uploadCroppedImg(cropper.getCroppedCanvas().toDataURL());
      setCropModalOpen(false);
    }
  };
  const uploadCroppedImg = (base64data) => {
    var real_str = base64data.slice(22);
    setShowAnimationflag(true);

    dispatch({
      type: postImage.type,
      payload: { base64Url: real_str },
    });
  };

  useEffect(() => {
    if (isPostingImageSuccess) UpscaleImage(PostImageResponse.url);
  }, [isPostingImageSuccess, PostImageResponse])

  const [selectedImage, setSelectedImage] = React.useState();
  async function UpscaleImage(image) {
    setSelectedImage(image);
    setShowAnimationflag(true);
    dispatch({
      type: upscaleImage.type,
      payload: { images: [image] }
    });
  }

  useEffect(() => {
    if (isUpscalingImageSuccess) {
      const merchifyArr = [];

      merchifyArr.push({
        id_val: 0,
        original: selectedImage,
        upscaled: UpscaleImageResponseDto.results[0].upscaled,
      });

      localStorage.setItem("mergify", JSON.stringify(merchifyArr));
      localStorage.setItem("job_id", ",,,,,");
  
      setCheckoutState({ ...checkoutState, flow: 'upload' });

      dispatch(resetUpscaleImage());
      navigate("/select-merch");
    } else if (isUpscalingImageFailed) {
      setShowAnimationflag(false);
      dispatch(resetUpscaleImage());
      //swal popup that says something went wrong and re-try message
      Swal.fire({
        icon: 'error',
        text: 'Oops! Something went wrong. Please try again.',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) UpscaleImage(selectedImage);
      })
    }
  }, [isUpscalingImageSuccess, isUpscalingImageFailed, UpscaleImageResponseDto])

  const handleImage = async (files) => {
    if (!files || files.length == 0) {
      return;
    }
    const file = files[0];

    if (!file) return;

    if (file.type === 'image/heif' || file.type === 'image/heic') {
      // const convertedData = await convertHeicToPng(file);
      // setPicture(convertedData);
      // setPreviewImage(convertedData);
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      await new Promise(resolve => reader.onload = resolve);
      const base64data = reader.result;
      setPicture(reader.result);
      setPreviewImage(base64data);
    }
  };

  // const convertHeicToPng = async (heicFile) => {
  //   const sharpImage = await sharp(heicFile).png().toBuffer();
  //   // const pngArray = await heic2any({
  //   //   blob: heicFile,
  //   //   toType: 'image/png'
  //   // });

  //   const pngBlob = new Blob([sharpImage]);
  //   const pngDataUrl = await new Promise(resolve => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result);
  //     reader.readAsDataURL(pngBlob);
  //   });

  //   return pngDataUrl;
  // }

  const onClickUpload = () => {
    if (!isLoggedIn()) {
      navigate("/signin");
    } else {
      if (previewImage) setCropModalOpen(true);
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      // 'image/*': ['.jpeg', '.png', '.jpg', '.webp', '.heic', '.heif']
      'image/*': ['.jpeg', '.png', '.jpg', '.webp']
    },
    onDrop: acceptedFiles => {
      handleImage(acceptedFiles);
    },
    maxFiles: 1,
  });

  const [isDragActive, setIsDragActive] = useState(false);

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };
  // Upload Image: end

  // Apply KYA: start
  const [kyaState, setKyaState] = useState('disclaimer');
  const confirmDisclaimer = () => {
    setKyaState('apply');
  }

  const [kyaName, setKyaName] = useState('');
  const [kyaEmail, setKyaEmail] = useState('');
  const [kyaWebsite, setKyaWebsite] = useState('');
  const [kyaInstagram, setKyaInstagram] = useState('');
  const [kyaTwitter, setKyaTwitter] = useState('');
  const [kyaFacebook, setKyaFacebook] = useState('');

  const emailRegex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/;
  const websiteRegex = /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(?:\/[\w\-._~:/?#[\]@!$&'()*+,;=]*)?$/;
  const applyKYA = () => {
    if (kyaName && kyaEmail && emailRegex.test(kyaEmail) && kyaWebsite && websiteRegex.test(kyaWebsite)) {
      //we need to post the above details

      setKyaState('success');
    }
  }

  const returnToAIArt = () => {
    const aiArtTab = document.getElementById('ai_art_tab');
    aiArtTab.click();
  }
  // Apply KYA: end

  return (
    <>
      {currentUser?.role?.toLowerCase() === "superadmin" || currentUser?.role?.toLowerCase() === "artist" ? (
        <>
          {showAnimationflag && (
            <div className="new_gif" style={{ display: "flex", top: 0, left: 0 }}>
              <img
                src={withPrefix("assets/img/green-little-balls.gif")}
                className="img-fluid mw_50"
                alt="loading"
              />
            </div>
          )}

          <div className="box">
            <div className="box-inn">
              <div>
                <label>Upload:</label>
                {!previewImage && (
                  <div 
                  {...getRootProps({ })} 
                  style={{ ...styles.dropzone, border: `1px dashed ${isDragActive ? '#A020F0' : '#000000'}` }}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                    <input {...getInputProps()} />
                    <img src={withPrefix("assets/img/upload-img.svg")} alt="upload-icon" />
                    <p style={{ ...styles.dropzoneText, marginTop: 24 }}>Drag & Drop to Upload File</p>
                    <p style={styles.dropzoneText}>or</p>
                    <button style={styles.browseBtn}>Browse File</button>
                  </div>
                )}
            
                {previewImage && (
                  <div style={styles.thumbsContainer}>
                    <div style={styles.thumb}>
                      <div style={styles.thumbInner}>
                        <img src={previewImage} style={styles.img} alt="preview" />
                      </div>
                      <div style={styles.iconCloseBox}>
                        <a onClick={() => setPreviewImage('')} className="far fa-times" style={styles.iconClose}></a>
                      </div>
                    </div>
                  </div>
                )}

                <CropImageModal 
                  cropModalOpen={cropModalOpen} 
                  setCropModalOpen={setCropModalOpen} 
                  picture={picture}
                  cropper={cropper} 
                  setCropper={setCropper}
                  getCropData={getCropData}
                />
              </div>
            </div>
            <p>By clicking “Upload” I’m agreeing to the site-wide <a href="/content-policy" target="_blank"> content policy.</a></p>
            <div className="btn-out">
              <button name="btn_upload" id="btn_upload" className="btn" onClick={onClickUpload}>
                Upload
              </button>
            </div>
          </div>        
        </>
      ) : (
        <div className="box">
          {kyaState == 'disclaimer' && (
            <>
              <div className="box-inn">
                <div>
                  <label>Upload:</label>
                  <p style={styles.kyaText}>Hi there! To make sure everything is secure, we'll need to verify your profile before you can upload any artwork. This is a standard part of our Know Your Artist ("KYA") <a href="/content-policy#kya_policy" target="_blank"> policy</a>. Click below to register.</p>
                </div>
              </div>
              <div className="btn-out">
                <button name="btn_upload" id="btn_upload" onClick={confirmDisclaimer} className="btn">
                  APPLY FOR KYA COMPLIANCE
                </button>
              </div>            
            </>
          )}
          {kyaState == 'apply' && (
            <>
              <div className="box-inn">
                <div>
                  <label>Apply for KYA:</label>
                  <div className="container" style={{ padding: 0 }}>
                    <div className="row" style={{ margin: '0 -5px' }}>
                      <div className="col-12" style={{ padding: '0 5px' }}>
                        <input value={kyaName} onChange={(e) => setKyaName(e.target.value)} type="text" required style={styles.input} name="kya_name" placeholder="Name*" />
                      </div>
                      <div className="col-12" style={{ padding: '0 5px' }}>
                        <input value={kyaEmail} onChange={(e) => setKyaEmail(e.target.value)} type="email" required style={styles.input} name="kya_email" placeholder="E-mail*" />
                      </div>
                      <div className="col-12" style={{ padding: '0 5px' }}>
                        <input value={kyaWebsite} onChange={(e) => setKyaWebsite(e.target.value)} type="text" required style={styles.input} name="kya_website" placeholder="Website/Portfolio*" />
                      </div>
                      <div className="col-lg-4" style={{ padding: '0 5px' }}>
                        <input value={kyaInstagram} onChange={(e) => setKyaInstagram(e.target.value)} type="text" style={styles.input} name="kya_website" placeholder="/Instagram" />
                      </div>
                      <div className="col-lg-4" style={{ padding: '0 5px' }}>
                        <input value={kyaTwitter} onChange={(e) => setKyaTwitter(e.target.value)} type="text" style={styles.input} name="kya_website" placeholder="/Twitter" />
                      </div>
                      <div className="col-lg-4" style={{ padding: '0 5px' }}>
                        <input value={kyaFacebook} onChange={(e) => setKyaFacebook(e.target.value)} type="text" style={styles.input} name="kya_website" placeholder="/Facebook" />
                      </div>
                    </div>
                  </div>
                  
                  <p style={styles.kyaShareLinkText}>Please share the link to your personal website or portfolio where we can explore your body of work and the handles of your social media accounts (e.g., Instagram, Twitter, Facebook). </p>
                  <p style={styles.kyaApplyTYText}>These fields are essential for establishing your identity and artistic profession. We respect your privacy and assure you the information provided is treated with utmost confidentiality per our <a href="/content-policy#kya_policy" target="_blank"> KYA Policy</a>. Thank you.</p>
                </div>
              </div>
              <div className="btn-out">
                <button name="btn_upload" id="btn_upload" onClick={applyKYA} className="btn" style={{ background: '#5757FF', color: '#fff' }}>
                  APPLY FOR KYA COMPLIANCE
                </button>
              </div>            
            </>
          )}
          {kyaState == 'success' && (
            <>
              <div className="box-inn">
                <div>
                  <label>Success!</label>
                  <p style={styles.kyaText}>
                    Thank you for submitting your KYA information. We appreciate your cooperation in ensuring compliance with our requirements. 
                  </p>
                  <p style={styles.kyaText}>
                    Our team will review your information and get back to you as soon as possible. If we need any further information, we'll be in touch. Thank you again for your prompt response.
                  </p>
                </div>
              </div>
              <div className="btn-out">
                <button name="btn_upload" id="btn_upload" onClick={returnToAIArt} className="btn" style={{ background: '#5757FF', color: '#fff' }}>
                  return to AI art 
                </button>
              </div>            
            </>
          )}
        </div>
      )}
    </>
  );
}
