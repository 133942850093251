import * as React from "react";
import { useEffect } from "react";
import { withPrefix } from "gatsby";
import { useApplicationContext } from "../../provider";
import { navigate } from "gatsby";
import { isLoggedIn } from "../services/auth";
import { Helmet } from "react-helmet";
import Footer from "../components/footer-1";
import Header from "../components/header";
import RemixSlots from "../components/remix-slots";
import "../components/css/beta.css";
import UploadImg from "../components/upload-img";
import ReactPlayer from 'react-player';
import { useMediaQuery } from "@mui/material";

const errorText = {
  color: 'red',
  fontFamily: '"Roboto", sans-serif',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '22px',
  paddingTop: 0,
}

const FeaturedImagePrompts = {
  threeD: 'a fabric pattern featuring a statue of women with their eyes closed, an art deco sculpture, featured, porcelain white faces, futuristic dramatic lighting, ivory carved, beautiful smooth oval shape faces, extremely detailed',
  abstract: 'a painting of blue, white, and orange squares, featured, cold metallic atmosphere, brown and cyan blue color scheme, expressive emotional piece, loosely cropped, on a canvas, gold and silver shapes, monoliths, diffractions of light, smooth textures',
  digitalArt: 'A close-up, award-winning image of a vibrant rainbow-colored cloth, showcasing a stunning color field with a mesmerizing blend of hues, accompanied by a background of dynamic and vivid digital screens displaying a captivating array of colors.',
  nature: 'Create a vivid and enchanting image that captures the essence of a lush green forest, intricately designed as a detailed matte painting. This fantasy art masterpiece should resemble a screenshot from a magical realm, skillfully crafted with the finesse of an oil painting. Depict an overpopulated scene of celestial gardens, teeming with ethereal beings and otherworldly flora. The large scale photo should evoke the rejuvenating spirit of spring, inviting viewers to lose themselves in its enchanting depths.',
  popArt: `Create a captivating image featuring a dynamic pop art painting that showcases a dramatic cloud with a powerful lightning bolt emerging from it. The artwork should be inspired by the 1970s comic book style and vorticism movement, with a striking black background adorned with shimmering stars. The scene should evoke a stormy atmosphere, as if taken from a detail shot of an artist's impression. This masterpiece will be the centerpiece of a magazine cover, capturing the essence of a turbulent yet mesmerizing celestial event.`,
  patterns: `Create a 16k upscaled image featuring a seamless pattern of gold chains and scissors intertwined on a black, textured background, inspired by the Rococo era and hip-hop culture. The design should be suitable for silk screen printing on various cloth materials, including vessels. The image should evoke a sense of dormant power, reminiscent of a rapper's flashy style, and be ideal for use in textile production.`,
}

export default function Home() {
  const matchesIPad = useMediaQuery("(max-width:991px)");
  const currentUser = typeof window !== "undefined" && JSON.parse(localStorage.getItem("currentUser") || "{}");
  let credits = currentUser.credits;
  const { setCreditCount } = useApplicationContext();
  let conversion = parseFloat(credits);
  let credit = conversion.toFixed(2);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/");
    }

    setState({ ...state, detailedDescription: applicationState.detailedDescription });
    setCreditCount(credit);
  }, []);

  const [state, setState] = React.useState({
    detailedDescription: "",
    detailedDescription2: "",
  });

  const [active, setActive] = React.useState('Doggies');

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (e.target.name === 'detailedDescription') setShowTextareaError(false);
    if (e.target.name === 'detailedDescription2') setShowTextarea2Error(false);
  };

  const { applicationState, setApplicationState, checkoutState, setCheckoutState } = useApplicationContext();

  const [showTextareaError, setShowTextareaError] = React.useState(false);
  const onGenerateClick = (e) => {
    e.preventDefault();

    if (!state.detailedDescription) {
      setShowTextareaError(true);
      return;
    }

    setApplicationState({
      ...applicationState,
      detailedDescription: state.detailedDescription,
    });
    if (isLoggedIn()) {
      if(currentUser.credits < 1) {
        navigate("pricing-options")
        return;
      }
      setCheckoutState({ ...checkoutState, flow: 'create' });
      navigate("/choose-art");
      return;
    } else {
      navigate("/signin");
      return;
    }
  };

  const [showTextarea2Error, setShowTextarea2Error] = React.useState(false);
  const onGenerateClick2 = (e) => {
    e.preventDefault();

    if (!state.detailedDescription2) {
      setShowTextarea2Error(true);
      return;
    }
    
    setApplicationState({
      ...applicationState,
      detailedDescription: state.detailedDescription2,
    });
    if (isLoggedIn()) {
      navigate("/choose-art");
    } else {
      navigate("/signin");
    }
  };

  const onLetsgoClick = (e) => {
    e.preventDefault();
    if (isLoggedIn()) {
      navigate("/search");
    } else {
      navigate("/signin");
    }
  };
  const onActive= (name) =>{
    window.location.href = `/search?keyword=${name}`;
  }

  const onUploadClick = () => {
    if (!isLoggedIn()) {
      setApplicationState({
        ...applicationState,
        homepageUploadTab: true,
      });
      navigate("/signin");
    }
  }

  useEffect(() => {
    if (isLoggedIn() && applicationState.homepageUploadTab) {
      setApplicationState({
        ...applicationState,
        homepageUploadTab: false,
      });

      const uploadTab = document.getElementById('upload_tab');
      uploadTab.click();
    }
  }, [isLoggedIn(), applicationState])

  const homepageVideoUrl = 'https://youtu.be/M7U-uv45_As';

  return (
    <>
      <div className="beta-page">
        <Helmet>
          <link href={withPrefix("assets/css/custom-styles.css")} rel="stylesheet" />
        </Helmet>
        <div className="container-main" id="page">
          <Header></Header>
          <main className="content-main">
            <div className="split-cont" style={{ paddingTop: 10 }}>
              <div className="container">
                <div className="row" style={{ alignItems: 'flex-start', marginTop: '30px' }}>
                  <div className="col-lg-6">
                    <div className="feature-links">
                      <h3>Create</h3>

                      <div className="tab-links2">
                        <ul className="nav">
                          <li>
                            <a id="ai_art_tab" href="#ai_art" className="active" data-bs-toggle="tab">
                              <p>AI Art</p>
                            </a>
                          </li>
                          <li>
                            <a id="upload_tab" href="#upload" data-bs-toggle="tab" onClick={onUploadClick}>
                              <p>Upload</p>
                            </a>
                          </li>
                        </ul>
                        <div className="sml-txt">.20 credit /image</div>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div className="tab-pane active" id="ai_art">
                        <div className="box">
                          <div className="box-inn">
                            <form>
                              <label>Enter a detailed description:</label>
                              <span style={{ color: "red", fontSize: 13 }} id="error_create_art" />
                              <div style={{ padding: 24, borderRadius: '16px', boxShadow: 'inset #595a5c 0 0 0 1px' }}>
                                <textarea name="detailedDescription" id="commentsart2" className="custom-scrollbar" cols={5} rows={5} placeholder="a colorful abstract textile pattern featuring glitter and sparkles and unicorns, seamless, 8K, intricate, contest winner" value={state.detailedDescription} onChange={onChange} style={{ padding: 0, border: 'none' }} />
                              </div>
                              {showTextareaError && <p style={{ ...errorText, marginTop: 8 }}>Oops! You forgot to enter a prompt.</p>}
                            </form>
                          </div>
                          <p style={{ paddingBottom: 0, marginTop: 36 }}>By clicking “Generate Art” I’m agreeing to the site-wide <a href="/content-policy" target="_blank"> content policy.</a></p>
                          <p>Images generated by stable diffusion. <a href="https://stability.ai" target="_blank">Learn more.</a></p>
                          <div className="btn-out">
                            <button name="btn_generate" id="btn_generate" className="btn" form="get-detailed-description" onClick={onGenerateClick} >
                              GENERATE ART
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="upload">
                        <UploadImg />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="feature-list" style={{ paddingTop: 3, paddingBottom: 0 }}>
                      <div className="heading-top">
                        <div className="aside">
                          <h4>One click merchification<br/> &amp; e-Commerce</h4>
                          <p>Begin selling in minutes:</p>
                        </div>
                      </div>
                      <div className="article-wrap">
                        <div className="article-list">
                          <div className="article-out">
                            <div className="article">
                              <div className="box">
                                <div className="figure">
                                  <img src={withPrefix("assets/img/photo-1.png")} alt="photo-1" />
                                </div>
                                <h3>1. Submit Art</h3>
                              </div>
                            </div>
                            <div className="article">
                              <div className="box">
                                <div className="figure">
                                  <img src={withPrefix("assets/img/photo-2.png")} alt="photo-2" />
                                </div>
                                <h3>2. Make Merchandise</h3>
                              </div>
                            </div>
                            <div className="article">
                              <div className="box">
                                <div className="figure">
                                  <img src={withPrefix("assets/img/photo-3.png")} alt="photo-1" />
                                </div>
                                <h3>3. Sell Merch Online</h3>
                              </div>
                            </div>
                            <div className="arrows">
                              <a href="#" className="arrow-right purple">
                                <em className="fal fa-arrow-right" />
                              </a>
                              <a href="#" className="arrow-right">
                                <em className="fal fa-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ marginTop: '13px' }}>
                        <p style={{ paddingBottom: 18, fontWeight: '500', color: '#595a5c', fontFamily: '"Albert Sans", sans-serif' }}>Find out how it works:</p>
                        <div style={{ width: '100%', minHeight: '284px', borderRadius: '16px', display: matchesIPad ? 'flex': 'block', justifyContent: 'center' }}>
                          <ReactPlayer width={'100%'} height={'284px'} style={{ borderRadius: '16px', overflow: 'hidden', maxWidth: '600px' }} controls={true} url={homepageVideoUrl} />
                        </div>
                      </div>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="split-cont2">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="figure" id="imageDiffusion">
                      <img
                        id="testimg"
                        src={withPrefix("assets/img/photo-4.png")}
                        alt="photo-4"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="feature-links">
                      <h3>Popular categories</h3>
                      <div className="aside-wrap">
                        <div className="aside">
                          <ul>
                            <li className={`${active === "Doggies" ? 'active' : ''}`}>
                              <a  onClick={()=>onActive("Doggies")} href="#">Doggies</a>
                            </li>
                            <li className={`${active === "Outer Space" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Outer Space")} href="#">Outer Space</a>
                            </li>
                            <li className={`${active === "Cars and Trucks" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Cars and Trucks")}  href="#">Cars and Trucks</a>
                            </li>
                            <li className={`${active === "Bubbles and Balloons" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Bubbles and Balloons")}  href="#">Bubbles and Balloons</a>
                            </li>
                            <li className={`${active === "Diamonds and Gems" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Diamonds and Gems")}  href="#">Diamonds and Gems</a>
                            </li>
                            <li className={`${active === "Superhero and Villains" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Superhero and Villains")}  href="#">Superhero and Villains</a>
                            </li>
                            <li className={`${active === "Fashion and Style" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Fashion and Style")}  href="#">Fashion and Style</a>
                            </li>
                            <li className={`${active === "Cartoons and Comics" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Cartoons and Comics")}  href="#">Cartoons and Comics</a>
                            </li>
                            <li className={`${active === "Video Games and Technology" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Video Games and Technology")}  href="#">Video Games and Technology</a>
                            </li>
                            <li className={`${active === "Animal and Nature" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Animal and Nature")}  href="#">Animal and Nature</a>
                            </li>
                            <li className={`${active === "Digital Media and Graphics" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Digital Media and Graphics")}  href="#">Digital Media and Graphics</a>
                            </li>
                            <li className={`${active === "Sports and Fitness" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Sports and Fitness")}  href="#">Sports and Fitness</a>
                            </li>
                            <li className={`${active === "Food and Cooking" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Food and Cooking")}  href="#">Food and Cooking</a>
                            </li>
                            <li className={`${active === "Music and Dance" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Music and Dance")}  href="#">Music and Dance</a>
                            </li>
                            <li className={`${active === "Jewelry and Accessories" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Jewelry and Accessories")}  href="#">Jewelry and Accessories</a>
                            </li>
                            <li className={`${active === "Science and Technology" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Science and Technology")}  href="#">Science and Technology</a>
                            </li>
                            <li className={`${active === "Travel and Adventure" ? 'active' : ''}`}>
                              <a onClick={()=>onActive("Travel and Adventure")}  href="#">Travel and Adventure</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="introducing-cont">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="heading-txt">
                      <div className="sub-title">Introducing</div>
                      <div className="sub-head">Remixer</div>
                      <h2>
                        Edit existing
                        <br /> images
                      </h2>
                      <p>
                        Over 10,000,000 amazing images available through Lexica
                        art.
                      </p>
                      <div className="btn-out">
                        <a href="#" className="btn" onClick={onLetsgoClick}>
                          let's go
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="figure">
                      <img
                        src={withPrefix("assets/img/introducing-group.png")}
                        alt="introducing-group"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="featured-images">
              <div className="container">
                <div className="heading-txt">
                  <div className="sub-title">Featured images</div>
                  <h2>Click to Remix</h2>
                  <p>Start creating</p>
                </div>
                <div className="row">
                  <RemixSlots 
                    imgSrc={withPrefix("assets/img/featured-image-1.png")} 
                    userPrompt="3D"
                    prompt={FeaturedImagePrompts.threeD}
                  />
                  <RemixSlots 
                    imgSrc={withPrefix("assets/img/featured-image-2.png")} 
                    userPrompt="Abstract" 
                    prompt={FeaturedImagePrompts.abstract}
                  />
                  <RemixSlots 
                    imgSrc={withPrefix("assets/img/featured-image-3.png")} 
                    userPrompt="Digital Art" 
                    prompt={FeaturedImagePrompts.digitalArt}
                  />
                  <RemixSlots 
                    imgSrc={withPrefix("assets/img/featured-image-4.png")} 
                    userPrompt="Nature" 
                    prompt={FeaturedImagePrompts.nature}
                  />
                  <RemixSlots 
                    imgSrc={withPrefix("assets/img/featured-image-5.png")} 
                    userPrompt="Pop Art" 
                    prompt={FeaturedImagePrompts.popArt}
                  />
                  <RemixSlots 
                    imgSrc={withPrefix("assets/img/featured-image-6.png")} 
                    userPrompt="Patterns" 
                    prompt={FeaturedImagePrompts.patterns}
                  />
                </div>
              </div>
            </div>
            <div className="generate-art-form">
              <div className="container">
                <div className="box">
                  <form method="get" action="">
                    <label>What do you want to see?</label>
                    <textarea name="detailedDescription2" id="commentsart" cols={5} rows={5} placeholder="a colorful abstract textile pattern featuring glitter and sparkles and unicorns, seamless, 8K, intricate, contest winner" className="custom-scrollbar" value={state.detailedDescription2} onChange={onChange} />
                    {showTextarea2Error && <p style={errorText}>Oops! You forgot to enter a prompt.</p>}
                  </form>
                  <p style={{ maxWidth: '100%' }}>By clicking “Generate Art” I’m agreeing to the site-wide <a href="/content-policy" target="_blank"> content policy.</a></p>
                  <p>Images generated by stable diffusion. <a href="https://stability.ai" target="_blank">Learn more.</a></p>
                </div>
                <div className="btn-out">
                  <button name="btn_generate" id="btn_generate" className="btn" form="get-detailed-description" onClick={onGenerateClick2} >
                    GENERATE ART
                  </button>
                </div>
              </div>
            </div>
          </main>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
}
